
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>会员权益体系</h3>
      <p>建立企业私域流量会员权益体系，促进用户增长和行业发展</p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="content">
      <div v-for="(v, index) in list" :key="index" class="Check">
        <h6>{{ v.title }}</h6>
        <p>
          {{ v.content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {
      list: [
        {
          title: "建立会员权益体系",
          content:
            "记录跟踪用户的消费行为，根据用户消费价值划分会员等级，再根据等级设置不同的会员权益，明确用户人群画像、标签等重要特征。其次再分析用户信息的大数据，提高用户的重复消费率和活动度，进行正确的数字营销。",
        },
        {
          title: "设计会员权益",
          content:
            "以留存型、互惠型和口碑型三种会员权益设置方式，提高用户留存、促进复购率，树立企业品牌，强调企业形象，为高等级用户提供优越感。采用固定+增值类权益，落实用户权益差异。",
        },
        {
          title: "设定用户使用率高、有兴趣的核心权益",
          content:
            "会员权益体系需要设置核心权益，即企业主业发展目标与用户核心诉求的融合。",
        },
        {
          title: "提高会员权益的使用率和兑换率",
          content:
            "通过多种网络渠道进行宣传，增强企业与用户的互动性，利用多种渠道将权益的概念触达到用户，形成良性的闭环。",
        },
        {
          title: "多元化的营销主题活动",
          content:
            "不定期的主题营销活动是有必要的，且在营销活动中，要重视不同用户对不同会员权益的概念。",
        },
        {
          title: "完善的会员体系需要控制机制和数据监控",
          content:
            "后台系统至少包括用户层次的界定、会员信息的实时变更和对应权益的配置。营销活动需要控制活动成本费用，需要高效合理的控制机制。",
        },
      ],
      Equity: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onEquity(i) {
      this.Equity = i;
    },
    onapply() {
      // this.childEvent(true);
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/Banner_1.png);
  background-size: 100% 100%;
  text-align: center;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    font-size: 0.2rem;
    line-height: 0.8rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.content {
  width: 14.4rem;
  height: 6.82rem;
  margin: 1.63rem auto;
  background-color: #fffff5;
  box-sizing: border-box;
  padding: 0.68rem 1.89rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    box-sizing: border-box;
    width: 49%;
    padding: 0.28rem;
    margin-bottom: 0.3rem;
    h6 {
      font-size: 0.22rem;
      line-height: 0.4rem;
      padding-bottom: 0.1rem;
    }
    p {
      font-size: 0.16rem;
      color: #666;
      line-height: 0.24rem;
    }
  }
}
.Check{
    transition: all 1.5s;
}
.Check:hover {
  background-color: #ffc53d;
  border-radius: 0.05rem;
  color: #fff;
  p {
    color: #fff !important;
  }
}
@keyframes abc {
  0% {
    top: 0.8rem;
  }
  50%{
    top:1.2rem
  }
  100% {
    top:0.8rem ;
  }
}
</style>
